define("fx/templates/plans/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FG67YpYY",
    "block": "[[[10,\"main\"],[14,0,\"no-bg\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"content--small\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[1,[28,[35,1],[\"plans.index.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"space-between\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@plan\",\"@subscribe\"],[[30,1],[30,0,[\"checkoutSessionCreate\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"plan\"],false,[\"content\",\"t\",\"each\",\"-track-array\",\"plan-card\"]]",
    "moduleName": "fx/templates/plans/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});